<template>
  <div class="Personal_Public">
    <div
      class="Personal_Public-content"
      :style="{ 'min-height': clientH, 'background-color': '#ffffff' }"
    >
      <div
        :style="{
          'min-height': clientH,
          display: 'flex',
          'background-color': '#ffffff'
        }"
      >
        <div style="min-width:274px;border-right: 1px solid #ebebeb;">
          <div class="infoBox">
            <img
              :src="
                info.Photo
                  ? info.Photo
                  : 'https://smart-resource.sikegroup.com/image/jpg/avater.png'
              "
              alt="照片"
            />
            <div class="title">
              {{ info.Name }}
              <span
                class="iconSex "
                :style="{ background: info.Sex == 1 ? '#FF4081' : '#13C2C2' }"
              >
                <a-icon v-if="info.Sex == 1" type="woman" />
                <a-icon v-else type="man" />
              </span>
            </div>
            <div class="item">
              账号:<span class="info"
                >{{ info.Qualification ? info.Qualification : 0 }}年</span
              >
            </div>
            <div class="item">
              英文名:<span class="info">{{ info.Qualification }}</span>
            </div>
            <div class="item">
              生日:<span class="info">{{ info.Source }}</span>
            </div>
            <div class="item">
              手机:<span class="info">{{ info.Mobile }}</span>
            </div>
            <div class="item">
              工作邮箱:<span class="info">{{ info.Email }}</span>
            </div>
            <div class="item">
              部门:<span class="info">{{ info.Speciality }}</span>
            </div>
            <div class="item">
              职位:<span class="info">{{ info.Position }}</span>
            </div>
            <div class="item">
              工作地址:<span class="info">{{ info.CurrentResidence }}</span>
            </div>
            <div
              class="item"
              style="min-height:30px;line-height: 24px;margin-bottom: 20px; display: unset;"
            >
              个人标签:
              <a-popconfirm
                v-for="(list, k) in min_titles"
                :key="k"
                :title="`是否删除${list.TagName}标签?`"
                @confirm="confirm(list.Id, k)"
                okText="是"
                cancelText="否"
              >
                <span class="info btnBar">{{ list.TagName }}</span>
              </a-popconfirm>
              <a-icon
                type="plus-circle"
                class="ant-menu-item-selected"
                @click="personTitle = true"
                style="margin-left:10px;"
              />
            </div>
            <div style="margin-top:20px;">
              <div class="item">
                余假(单位:小时) <span class="info">{{ 4.5 }}</span>
              </div>
              <div class="item">
                年假(单位:小时) <span class="info">{{ 4.5 }}</span>
              </div>
              <div class="item">
                其他有薪假(单位:小时) <span class="info">{{ 4.5 }}</span>
              </div>
              <div class="item">
                产检假(单位:小时) <span class="info">{{ 4.5 }}</span>
              </div>
              <div class="item">
                产假/陪产假(单位:小时) <span class="info">{{ 4.5 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="person_header">
          <div class="flexBox" style="align-items: center;">
            <div>
              <span
                :class="{
                  tabs: true,
                  'bt-3': indexTab == 1,
                  'ant-menu-item-selected': indexTab == 1
                }"
                @click="tabFn(1)"
                >个人信息</span
              >
              <span
                :class="{
                  tabs: true,
                  'bt-3': indexTab == 2,
                  'ant-menu-item-selected': indexTab == 2
                }"
                @click="tabFn(2)"
                >跟踪信息</span
              >
              <span
                :class="{
                  tabs: true,
                  'bt-3': indexTab == 3,
                  'ant-menu-item-selected': indexTab == 3
                }"
                @click="tabFn(3)"
                >操作日志</span
              >
              <span
                :class="{
                  tabs: true,
                  'bt-3': indexTab == 4,
                  'ant-menu-item-selected': indexTab == 4
                }"
                @click="tabFn(4)"
                >角色权限</span
              >
            </div>
            <a-dropdown :trigger="['click']" style="">
              <span class="ant-dropdown-link">
                <a-icon type="ordered-list" /> 更多操作</span
              >
              <a-menu slot="overlay">
                <!-- <a-menu-item key="0">
                                    <span @click="addRecordsFn('添加个人材料')">添加材料</span>
                                </a-menu-item> -->
                <a-menu-item key="1">
                  <span @click="drawerRecordsFn('添加附件')">添加附件</span>
                </a-menu-item>
                <!-- <a-menu-divider /> -->
                <a-menu-item key="2">
                  <span @click="drawerRecordsFn('续签合同')">续签合同</span>
                </a-menu-item>
                <a-menu-item key="3">
                  <span @click="drawerRecordsFn('入职转正')">入职转正</span>
                </a-menu-item>
                <a-menu-item key="4">
                  <span @click="drawerRecordsFn('岗位异动')">岗位异动</span>
                </a-menu-item>
                <a-menu-item key="5">
                  <span @click="drawerRecordsFn('添加奖罚')">添加奖罚</span>
                </a-menu-item>
                <a-menu-item key="6">
                  <span @click="drawerRecordsFn('普通跟踪')">普通跟踪</span>
                </a-menu-item>
                <a-menu-item key="7">
                  <span @click="drawerRecordsFn('办理离职')">办理离职</span>
                </a-menu-item>
                <a-menu-item key="8">
                  <span @click="drawerRecordsFn('添加离职详情')"
                    >添加离职详情</span
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <!-- <div>       
                            <a-button size="small" style="border-radius:15px;margin-left:16px;margin-top:4px;" class="border ant-menu-item-selected " @click="drawerFn('面试邀请')"> 面试邀请</a-button> 
                            <a-button size="small" style="border-radius:15px;margin-left:16px;margin-top:4px;" class="border ant-menu-item-selected " @click="drawerFn('确聘申请')"> 确聘申请</a-button>  
                            <a-button size="small" style="border-radius:15px;margin-left:16px;margin-top:4px;" class="border ant-menu-item-selected " @click="drawerFn('发offer')"> 发offer</a-button>                   
                        </div> -->
          </div>
          <a-divider style="margin-top:-4px;" />
          <router-view></router-view>
        </div>
      </div>
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>

    <a-drawer
      :title="controlType"
      width="380"
      placement="right"
      :closable="true"
      @close="onClose"
      :visible="drawerBool"
    >
      <div v-if="controlType == '添加附件'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="资料类型">
            <a-select v-model="CV_val">
              <a-select-option
                v-for="(item, k) in CV_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="" style="margin-top: 24px;">
            <a-upload-dragger
              name="file"
              @change="fileDrawerChange"
              :beforeUpload="fileBeforeUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">上传文件或将文件拖入</p>
              <!--  :multiple="true" action="https://www.mocky.io/v2/5cc8019d300000980a055e76" -->
              <p class="ant-upload-hint">
                支持文件格式：.doc,.docx,.txt,.rtf,.pdf,.html,.wps,等文本格式简历全部都支持解析。
              </p>
            </a-upload-dragger>
          </a-form-item>
        </a-form>
      </div>
      <div v-else-if="controlType == '续签合同'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="转正类型">
            <a-select v-model="tans_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in change_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="指派人">
            <person v-bind="contackObj" @getData="backfn"></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="生效日期">
            <a-date-picker @change="onChange" />
            <!-- :disabledDate="disabledDate" -->
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="抄送人">
            <person
              v-bind="CCopyObj"
              tyle="height: 38px;line-height: 20px;"
              @getData="CCopyFn"
            ></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="紧急程度">
            <a-select v-model="pressing_type" placeholder="请选择">
              <a-select-option
                v-for="item in pressing_type_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <!-- <a-divider orientation="left">发送消息通知</a-divider> -->
          <h3>发送消息通知</h3>
          <div class="ant-form-item_botton">
            <a-form-item v-bind="formItemLayout2" label="员工转正申请表">
            </a-form-item>
            <a-form-item v-bind="formItemLayout2" label="员工转正评估表(上级)">
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              label="员工转正评估表(上上级)"
            >
            </a-form-item>
            <a-form-item v-bind="formItemLayout2" label="员工转正评估表(HR)">
              {{ __USER__.name }}
            </a-form-item>
          </div>
        </a-form>
      </div>
      <div v-else-if="controlType == '入职转正'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="转正类型">
            <a-select v-model="tans_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in change_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="指派人">
            <a-input type="text" v-model="CVname" placeholder="请输入姓名" />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="生效日期">
            <a-date-picker @change="onChange" />
            <!-- :disabledDate="disabledDate" -->
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="抄送人">
            <person
              v-bind="CCopyObj"
              style="height: 38px;line-height: 20px;"
              @getData="CCopyFn"
            ></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="紧急程度">
            <a-select v-model="pressing_type" placeholder="请选择">
              <a-select-option
                v-for="item in pressing_type_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <!-- <a-divider orientation="left">发送消息通知</a-divider> -->
          <h3>发送消息通知</h3>
          <div class="ant-form-item_botton">
            <a-form-item v-bind="formItemLayout2" label="员工转正申请表">
            </a-form-item>
            <a-form-item v-bind="formItemLayout2" label="员工转正评估表(上级)">
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout2"
              label="员工转正评估表(上上级)"
            >
            </a-form-item>
            <a-form-item v-bind="formItemLayout2" label="员工转正评估表(HR)">
              {{ __USER__.name }}
            </a-form-item>
          </div>
        </a-form>
      </div>
      <div v-else-if="controlType == '岗位异动'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="异动类别">
            <a-select v-model="change_type" placeholder="请选择">
              <a-select-option
                v-for="(item, k) in change_type_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="原职位">
            员工
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="原部门"
            v-show="change_type == '借调'"
          >
            系统研发部
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            :label="
              change_type == '平调' || change_type == '借调'
                ? '异动后职位'
                : change_type + '后职位'
            "
          >
            <a-tree-select
              style="width:100%;"
              :treeData="posts_type_arr"
              v-model="posts_type"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              :showCheckedStrategy="SHOW_PARENT"
              searchPlaceholder="请选择岗位"
            />
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="异动后部门"
            v-show="change_type == '借调' || change_type == '平调'"
          >
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="departmentObj"
              @getData="departmentFn"
            ></person>
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="生效日期"
            v-show="change_type != '借调'"
          >
            <a-date-picker
              style="width:100%;"
              v-model="dateTimer"
              @change="onChange"
              placeholder="请选择日期"
            />
          </a-form-item>
          <a-form-item
            v-bind="formItemLayout"
            label="借调时间"
            v-show="change_type == '借调'"
          >
            <a-range-picker
              style="width:100%;"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="changeTime"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="原因">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <div v-else-if="controlType == '添加奖罚'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="奖惩类别">
            <a-select placeholder="请选择">
              <a-select-option value="奖励">{{ '奖励' }}</a-select-option>
              <a-select-option value="乐捐">{{ '乐捐' }}</a-select-option>
              <a-select-option value="处罚">{{ '处罚' }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="奖惩级别">
            <a-select placeholder="请选择">
              <a-select-option value="甲">{{ '甲级' }}</a-select-option>
              <a-select-option value="乙">{{ '乙级' }}</a-select-option>
              <a-select-option value="丙">{{ '丙级' }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="通报范围">
            <a-select placeholder="请选择">
              <a-select-option value="集团">{{ '集团' }}</a-select-option>
              <a-select-option value="城市">{{ '城市' }}</a-select-option>
              <a-select-option value="中心">{{ '中心' }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="事件日期">
            <a-date-picker
              style="width:100%;"
              v-model="dateTimer"
              @change="onChange"
              placeholder="请选择日期"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="奖罚内容">
            <a-textarea v-model="content" placeholder="请输入" :rows="4" />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="事件描述">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <div v-else-if="controlType == '普通跟踪'">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="事件日期">
            <a-date-picker
              style="width:100%;"
              v-model="dateTimer"
              @change="onChange"
              placeholder="请选择日期"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="事件描述">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <div v-else-if="controlType == '办理离职'" style="padding-bottom:60px;">
        <a-form>
          <a-form-item v-bind="formItemLayout" label="离职类别">
            <a-select placeholder="请选择">
              <a-select-option
                v-for="(item, k) in quit_type"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="离职原因">
            <a-select placeholder="请选择">
              <a-select-option value="合同到期终止">{{
                '合同到期终止'
              }}</a-select-option>
              <a-select-option value="协商解除/劝退">{{
                '协商解除/劝退'
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="人资面谈人">
            {{ __USER__.name }}
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="离职时间">
            <a-date-picker
              style="width:100%;"
              v-model="dateTimer"
              @change="onChange"
              placeholder="请选择日期"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="紧急程度">
            <a-select v-model="pressing_type" placeholder="请选择">
              <a-select-option
                v-for="item in pressing_type_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="详细说明">
            <a-textarea placeholder="请输入" :rows="4" />
          </a-form-item>
          <p>指派人</p>
          <a-form-item v-bind="formItemLayout" label="财务">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="contackObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="中心IT">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="contackObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="IT经理">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="contackObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <a-form-item label="抄送人（可多人）">
            <person
              style="height: 38px;line-height: 20px;"
              v-bind="CCopyObj"
              @getData="backfn"
            ></person>
          </a-form-item>
          <div style="color:#F5222D;">
            <p>规则说明：</p>
            <p>1、在确定员工离职后人事专员发离职工单;</p>
            <p>2、财务部确认借款已归还;</p>
            <p>3、中心IT收回IT设备和禁用域帐号和门禁</p>
            <p>4、总部IT禁用员工邮箱</p>
            <p>
              5、人事部查核清单,单独通知删除员工(孕期教育)、子女(早教课)的学位和排课,如有续课需要续费后按一般客户对待,最后更改km账号状态为离职;
            </p>
          </div>
        </a-form>
      </div>
      <!-- 添加离职详情 -->
      <div v-else>
        <a-form>
          <a-form-item label="详细内容">
            <a-textarea v-model="remarks" placeholder="请输入" :rows="4" />
          </a-form-item>
        </a-form>
      </div>

      <div class="drawer-btns" style="left:0%;padding-left:8%">
        <a-button type="default" @click="drawerBool = false" class="drawer-btn"
          >取消</a-button
        ><a-button class="drawer-btn" type="primary">提 交</a-button>
      </div>
    </a-drawer>
    <a-modal title="添加标签" centered v-model="personTitle" @ok="subTitle()">
      <a-input
        type="text"
        style="margin-bottom:10px;"
        v-model="addtitles"
        placeholder="多个用英文','隔开"
      />
      <span
        class="info btnBar"
        v-for="(list, k) in min_titles_arr"
        :key="k"
        @click="selectTitles(list)"
        >{{ list }}</span
      >
    </a-modal>
  </div>
</template>
<script>
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import person from '@/components/SelectPersonnel/SelectPersonnel' //选人
import { __arr_Education, __quit_type } from '@/assets/js/public_ren'
import moment from 'moment'
export default {
  name: 'Personal_Public',
  components: {
    person
  },
  data () {
    return {
      quit_type: __quit_type(),
      formItemLayout2: {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 }
      },
      SHOW_PARENT,
      id: '', //员工Id
      spinning: false,
      clientH: '400px',
      infoImg: [],

      info: {},
      indexTab: 1,
      personTitle: false, //添加标签 模态框
      min_titles: [], //添加标签（查到的标签）
      min_titles_arr: ['男婶', '超级大咖', '搞笑', '牛b'],
      addtitles: undefined, //添加标签
      fileList: [], //头像
      //可以公用,只要接收返回值就可以
      contackObj: {
        //选联系人
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      contack_ren: [], //选联系人 以及部门负责人
      CCopyObj: {
        //选抄送人
        rank: 0, //职级 0为不选，1为选
        nums: 10000, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      departmentObj: {
        //选部门
        rank: 0, //职级 0为不选，1为选
        nums: 1, //选的数量
        department: 2, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: ' ', //常用联系人 不要常用联系人传空''
        selectArr: [] //要传入的数据
      },
      CCopy_ren: [], //抄送人

      pressing_type_arr: [], //紧急程度
      pressing_type: [],
      // approveObj: {//选审批人
      //     rank: 0, //职级 0为不选，1为选
      //     nums: 1, //选的数量
      //     department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
      //     contacts: " ", //常用联系人 不要常用联系人传空''
      //     selectArr: [] //要传入的数据
      // },
      // approve_ren:[],//审批人
      // managementObj: {//选面试官
      //     rank: 0, //职级 0为不选，1为选
      //     nums: 10, //选的数量
      //     department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
      //     contacts: " ", //常用联系人 不要常用联系人传空''
      //     selectArr: [] //要传入的数据
      // },
      // management_ren:[],//面试官
      // departmentObj: {//入职部门
      //     rank: 0, //职级 0为不选，1为选
      //     nums: 1, //选的数量
      //     department: 2, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
      //     contacts: " ", //常用联系人 不要常用联系人传空''
      //     selectArr: [] //要传入的数据
      // },
      // department_ren:[],//

      drawerBool: false, //侧边栏的显隐
      controlType: '', //侧边栏的标题名
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },

      // timer_type_arr:[{value: '3个月',name:'3个月'},{value: '1个月',name:'1个月'},{value: '2个月',name:'2个月'},],
      // timer_type:undefined,//试用期
      // year_type_arr:[{value: '2年',name:'2年'},{value: '3年',name:'3年'},{value: '5年',name:'5年'},],
      // year_type:undefined,//合同期限
      // posts_type_arr: [],//岗位
      // posts_type: [],
      // remarks:undefined,//备注

      //添加附件
      CV_val: '',
      CV_arr: [
        { name: '入职登记表', value: '入职登记表' },
        { name: '入职简历', value: '入职简历' },
        { name: '个人简历', value: '个人简历' }
      ],

      //普通跟踪
      dateTimer: '',
      remarks: '',
      //异动类别
      change_type: '平调',
      change_type_arr: [
        { name: '平调', value: '平调' },
        { name: '借调', value: '借调' },
        { name: '晋升', value: '晋升' },
        { name: '降级', value: '降级' }
      ],
      posts_type_arr: [], //岗位
      posts_type: '', //异动后职位
      department_value: [], //借调后的部门
      startDate: '',
      endDate: ''
      //合同续签
    }
  },
  watch: {
    infoImg (val, info) {},
    indexTab (val, info) {
      console.log(val, info)
    }
  },

  created () {
    let that = this
    //    id:5d37238944ba905e9c37a7045e4653f4
    that.id = that.$route.query.personId
    console.log(that.id)
    if (that.$route.path == '/staffManagement/Staff/Personal_Info') {
      that.indexTab = 1
    } else if (that.$route.path == '/personnelModul/Staff/Track_Info') {
      that.indexTab = 2
    } else if (that.$route.path == '/personnelModul/Staff/Operation_Log') {
      that.indexTab = 3
    } else {
      that.indexTab = 4
    }
    this.$axios.post(9970, { Id: that.id }, res => {
      if (res.data.code == 1) {
        that.info = res.data.data
      } else {
        that.$message.error(res.data.msg)
      }
    })
    //紧急程度
    if (sessionStorage.getItem('pressing_type_arr')) {
      this.pressing_type_arr = JSON.parse(
        sessionStorage.getItem('pressing_type_arr')
      )
    } else {
      this.$axios.get(8006, {}, res => {
        if (res.data.code == 1) {
          this.pressing_type_arr = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
    that.initTitle()
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 156 + 'px'

    if (!sessionStorage.getItem('posts_type_arr')) {
      this.$axios.get(8007, {}, res => {
        if (res.data.code == 1) {
          this.posts_type_arr = res.data.data
          window.sessionStorage.setItem(
            'posts_type_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    } else {
      this.posts_type_arr = JSON.parse(
        window.sessionStorage.getItem('posts_type_arr')
      )
    }
  },
  methods: {
    // 模拟返回值
    backfn (val) {
      console.log(val)
    },
    moment,
    onChange (date, dateString) {
      console.log(date, dateString)
    },
    initTitle () {
      let that = this
      //查个人标签
      that.$axios.get(9969, { Id: that.id }, res => {
        if (res.data.code == 1) {
          that.min_titles = res.data.data
        }
      })
    },
    //选个人标签
    selectTitles (val) {
      if (this.addtitles) {
        this.addtitles = this.addtitles + ',' + val
      } else {
        this.addtitles = val
      }
    },
    //删除个人标签
    confirm (val, index) {
      console.log(val)
      let that = this
      that.$axios.post(9967, { tagId: val }, res => {
        console.log(res)
        let list = res.data
        if (!list) {
          return false
        }
        if (list.code == 1) {
          that.min_titles.splice(index, 1)
          that.$message.text('删除成功')
        } else {
          that.$message.error(list.msg)
        }
      })
    },
    //添加个人标签
    subTitle () {
      let that = this
      if (!that.addtitles) {
        that.$message.text('不能为空!')
        return false
      }
      that.spinning = true
      that.$axios.post(
        9968,
        {
          Id: that.id,
          TagName: that.addtitles,
          CreateUserId: that.__USER__.uid
        },
        res => {
          that.spinning = false
          if (res.code == 1) {
            that.$message.succerr('添加成功')
            let obj = { TagName: that.addtitles }
            obj.Id = res.data.tagId
            console.log(obj)
            that.min_titles.unshift(obj)
            console.log(that, min_titles)
            that.personTitle = false
            console.log(that.personTitle)
          } else {
            that.$message.error(res.data.msg)
          }
        }
      )
    },
    //时间
    changeTime (value) {
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
      // console.log(this.startDate,this.endDate)
    },

    //tab切换
    tabFn (num) {
      this.indexTab = num
      let url
      switch (num) {
        case 1:
          url = '/staffManagement/Staff/Personal_Info'
          break
        case 2:
          url = '/personnelModul/Staff/Track_Info'
          break
        case 3:
          url = '/personnelModul/Staff/Operation_Log'
          break
        case 4:
          url = '/personnelModul/Staff/Role_Jurisdiction'
          break
      }
      this.$router.push({
        path: url,
        query: {
          id: this.id
        }
      })
    },

    onClose () {
      //侧边栏隐藏
      this.controlType = ''
      this.drawerBool = false
    },
    // drawerFn(type){
    //    this.controlType = type;
    //    this.drawerBool = true;
    //     if(!sessionStorage.getItem('posts_type_arr')){
    //         this.$axios.get(8007,{},res=>{
    //             console.log(res)
    //             if (res.data.code==1) {
    //                 this.posts_type_arr = res.data.data;
    //                 window.sessionStorage.setItem('posts_type_arr',JSON.stringify(res.data.data));
    //             } else {
    //                 this.$message.error("获取数据失败!");
    //             }
    //         })
    //     }else{
    //         this.posts_type_arr = JSON.parse(window.sessionStorage.getItem('posts_type_arr'));
    //     }
    // },
    //岗位
    // onChangeTree(val){
    //    console.log(val);
    //    this.posts_type = val;
    // },
    // //选人
    // contackPerson(value) {
    //     // console.log(`选联系人 ${value}`);
    //     this.contack_ren= value;
    // },
    // CCPerson(value) {
    //     // console.log(`选抄送人 ${value}`);
    //     this.CC_ren= value;
    // },
    // approvePerson(value) {
    //     // console.log(`选审批人 ${value}`);
    //     this.approve_ren= value;
    // },
    // managementPerson(value) {
    //     // console.log(`选面试官 ${value}`);
    //     this.management_ren= value;
    // },
    // departmentPerson(value) {
    //     // console.log(`选入职部门 ${value}`);
    //     this.department_ren= value;
    // },
    departmentFn (val) {
      //借调后的部门
      this.department_value = val
    },
    //抄送人
    CCopyFn (val) {
      this.CCopy_ren = val
    },
    //更多操作
    drawerRecordsFn (type) {
      let that = this
      that.drawerBool = true
      that.controlType = type
    }
  }
}
</script>
<style lang="less" scoped>
.Personal_Public {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 0px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    line-height: 1.1;
    margin: 16px 0px 10px;
  }
  .item {
    height: 30px;
    line-height: 30px;
  }

  .Personal_Public-content {
    width: 100%;
    .infoBox {
      width: 270px;
      padding: 30px 0px 0px 20px;
      color: #999999;
      img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }

      .iconSex {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        font-size: 8px;
        text-align: center;
        line-height: 18px;
        color: #ffffff;
        // margin-top: 0px;
        vertical-align: middle;
      }
    }
  }

  .person_header {
    .flexBox {
      display: flex;
      justify-content: space-between;
    }
    .tabs {
      padding: 4px 0px;
      margin: 4px 26px 4px 0px;
      display: inline-block;
    }
    width: 100%;
    padding: 30px 20px;
  }
}
.info {
  margin-left: 10px;
  color: #333333;
}
.btnBar {
  color: #ffffff;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #f2a654;
  font-size: 8px;
  display: inline-block;
  height: 16px;
  line-height: 15px;
}
.ant-form-item {
  margin-bottom: 14px;
}
.ant-form-item_botton .ant-form-item {
  margin-bottom: 0px;
}
</style>
